import { Outlet } from "react-router-dom";

import styles from "./Apply.module.scss";

interface Props {
  h1: JSX.Element;
}

export default function Apply({ h1 }: Props) {
  return (
    <>
      <div className={styles.hero}>
        <h1>{h1}</h1>
      </div>
      <Outlet />
    </>
  );
}
