import React from "react";
import * as photos from "assets/photos";

import Image from "components/Image";
import Marquee from "components/Marquee";
import SectionTitle from "components/SectionTitle";

import { reviews } from "./fixture";
import ReviewCard from "./ReviewCard";

import styles from "./Reviews.module.scss";

export default function Reviews() {
  return (
    <div className={styles.reviews}>
      <SectionTitle
        subTitle={
          <>
            <span>하랑은 매년 새내기들이 수능을 보고</span>&nbsp;
            <span>대학에 입학하기 전 시기인 12월~2월에</span>&nbsp;
            <span>
              새내기 <strong>멘토링</strong>과 <strong>캠프</strong>를
              주최하였습니다
            </span>
          </>
        }
      >
        멘티 분들의 후기
      </SectionTitle>
      <Marquee direction="right">
        {Object.values(photos).map((photo) => (
          <div className={styles.photo} key={photo}>
            <Image
              src={photo}
              alt="하랑 멘토링"
              width="36rem"
              height="22rem"
              filter={true}
            />
          </div>
        ))}
      </Marquee>
      <ul>
        <Marquee direction="left" duration={120}>
          {reviews
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
            .map((review) => (
              <li key={review.id}>
                <ReviewCard
                  contents={review.contents}
                  nickname={review.nickname}
                  createdAt={review.createdAt}
                />
              </li>
            ))}
        </Marquee>
      </ul>
    </div>
  );
}
