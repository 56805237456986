import React from "react";
import parse from "html-react-parser";
import { replaceCharAt } from "utils";

import styles from "./ReviewCard.module.scss";
interface Props {
  contents: string;
  nickname: string;
  createdAt: string;
}

export default function ReviewCard({
  contents,
  nickname,
  createdAt,
  ...rest
}: Props) {
  return (
    <div className={styles.reviewcard} {...rest}>
      <div>{parse(contents)}</div>
      <div className={styles.footer}>
        <div className={styles.nickname}>{replaceCharAt(nickname, 2, "*")}</div>{" "}
        &mdash;
        <div className={styles.date}>{createdAt}</div>
      </div>
    </div>
  );
}
