import { useCallback, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import classNames from "classnames";
import { motion, type Variants } from "framer-motion";

import SectionTitle from "components/SectionTitle";

import texts from "./texts.json";

import styles from "./FAQ.module.scss";

const variants: Variants = {
  initial: {
    height: "0",
  },
  active: {
    height: "auto",
  },
};

export default function FAQ() {
  const [active, setActive] = useState(
    texts.reduce<Record<number, boolean>>(
      (acc, _, idx) => ({ ...acc, [idx]: false }),
      {}
    )
  );

  const onItemClick = useCallback(
    (idx: number) => {
      setActive({ ...active, [idx]: !active[idx] });
    },
    [active]
  );

  return (
    <section className={styles.faq}>
      <SectionTitle>자주 묻는 질문들</SectionTitle>
      <ul>
        {texts.map((el, idx) => (
          <li key={idx}>
            <div
              className={classNames(styles.question, {
                [styles.active_question]: active[idx],
              })}
              onClick={() => {
                onItemClick(idx);
              }}
            >
              {el.question}
              <AiOutlinePlus size="1.2em" />
            </div>
            <motion.div
              initial="initial"
              animate={active[idx] ? "active" : "initial"}
              transition={{ type: "linear" }}
              variants={variants}
              className={styles.answer}
            >
              <div>{el.answer}</div>
            </motion.div>{" "}
          </li>
        ))}
      </ul>
    </section>
  );
}
