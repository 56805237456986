import { Timestamp } from "firebase/firestore";
import { nanoid } from "nanoid";
import { type Review } from "types";

export const reviews: Review[] = [
  {
    id: nanoid(10),
    contents:
      "서로 처음 봐서 어색하기도 했지만 다들 친절하셔서 불편하진 않았어요! 전반적인 대학생활에 중점적으로 말하는 것보다 인생에 대한 전반적인 내용에 대해 말해주는 게 좋았던 것 같아요. 대학교만 붙어서 별 생각도 안하고 있었던 때에 들어서 그런지 더 좋았던 것 같습니다 &#128515; 재밌었습니다. 감사해요, 멘토님들 &#128518;",
    nickname: "경명",
    createdAt: "2020/01/18",
  },
  {
    id: nanoid(10),
    contents:
      "카카오톡 오픈 채팅으로 우연히 알게 되었는데 처음엔 많이 어색했지만 어색함을 풀어주시려고 다들 노력해주셔서 너무 행복했어요. 선배님이 궁금한 걸 정말 자세하게 알려주시고 다른 대학 꿀팁도 알려주셔서 저의 비전에 많은 이점을 줄 거 같아서 좋은 경험 이였습니다. 비전 캠프도 참여해서 더 많이 알아가고 좋은 기억 쌓아가고 싶습니다 ㅎㅎ 좋은 경험 감사합니다!!",
    nickname: "Jaims",
    createdAt: "2020/01/18",
  },
  {
    id: nanoid(10),
    contents:
      "학교생활 걱정도 되고 과에 대해서도 생각이 많았었는데 오늘 다양한 이야기를 들어서 유익했습니다. 자칫하면 쉽게 생각하고 흐르듯이 진학하는 대학교가 될 수 있는데 오늘 비전 이야기부터 진지하게 다시 한 번 생각하는 기회가 되었습니다.",
    nickname: "Esoap",
    createdAt: "2020/01/18",
  },
  {
    id: nanoid(10),
    contents:
      "올해 보낸 시간 중 가장 뜻깊은 시간이었습니다. 처음 신청하기 오기까지 조금의 부담도 있었지만 전공을 막론하고 해주시는 충고와 이야기들은 대학생활 4년 내내 기억될 것이라 생각합니다. 좋은 활동 기획해주셔서 감사합니다:>",
    nickname: "임지연",
    createdAt: "2020/01/04",
  },
  {
    id: nanoid(10),
    contents:
      "우선 멘토님들이 너무 착하시고 친절하셔서 진짜 좋았어요! 그동안 궁금했던 공연 동아리에 대한 부분도 직접 유경험자(?) 께서 조언해 주시고 이야기해주셔서 너무 좋았어요! 단순히 대학 생활에 대한 이야기뿐만 아니라 인생 얘기나 그도안 가져왔던 생각들에 대해 깊은 이야기를 나눌 수 있었던 좋은 시간이었던 것 같아요. 다음번에 기회되면 꼭 한 번 더 만나서 이야기 나누고 싶습니당 이렇게 많은 준비 해주셔서 감사합니다!!",
    nickname: "은서련",
    createdAt: "2020/01/04",
  },
  {
    id: nanoid(10),
    contents:
      "대학 생활을 시작하기 전에 대학 생활이 어떨지, 어떤 것을 하는지 등  단순한 것들을 차근차근 배울 수 있었던 시간을 가졌어요! 꼭 기회가 된다면 캠프도 참여해서 보다 더 자세한 것들을 알아가보고 싶어요. 다들 친절하게 설명해주셔서 너무 감사하고 좀 늦게 들어왔지만 퀴즈시간 재밌었어요&#128518;",
    nickname: "kim07130",
    createdAt: "2020/01/04",
  },
  {
    id: nanoid(10),
    contents:
      "평소 대학생활에 대해 궁금한 게 많았는데 궁금한 점도 여쭤보고 또 같은 학교 선배가 계셔서 더욱 유익한 시간이었어요~ 경영학과를 입학하게 되면서 회계사의 길을 가야하는지, 아니면 꿈꾸고 있었던 교육의 길로 가야하는지 고민했었는데 좋은 선배님들을 만나 저에게 도움이 될 조언들을 얻을 수 있었습니다. 정말 감사한 시간이었고, 앞으로도 기억에 남을 것 같아요~",
    nickname: "stella3254",
    createdAt: "2020/01/04",
  },
  {
    id: nanoid(10),
    contents:
      "생각했던 것보다 훨씬 더 많은 꿀팁과 정보를 알려주신 멘토 언니, 친근하신 얼굴로 환영해주신 하랑 분들 오늘 너무 너무 감사했습니다. 대학 합격하고 빈둥빈둥 놀고 있는 와중에 정말 유익하고 재미있는 시간을 보내게 되어서 신촌까지 달려온 걸음이 아깝지 않았던 것 같아요! 내년에 오게 될 후배분들도 이 기분을 느꼈으면 좋겠네요!!",
    nickname: "catyj21",
    createdAt: "2019/01/05",
  },
  {
    id: nanoid(10),
    contents:
      "오늘 19학번으로 참여했던 새내기입니다!! 선배님들께 평소 궁금했던 점들에 대해서 여쭤보고 같이 이야기할 수 있어서 너무 좋았습니다!! 대학 입학전에 저 자신에 대해서 많이 생각해보면서 잘 준비해 성공적인 대학생활을 할 수 있었으면 좋겠어요. 그리고 이러한 저의 꿈에 오늘 멘토링이 큰 도움이 된 것 같아요 정말 감사합니다&#129505;",
    nickname: "sssss507",
    createdAt: "2019/01/12",
  },
  {
    id: nanoid(10),
    contents:
      "오늘 멘토링에 친구 추천으로 오게 됐는데 생각보다 많은 팁들을 공유할 수 있어서 좋았습니다. 평소에 잘 꺼내지 못했던 대학교와 그 미래에 관련된 얘기를 하게 되어 기쁘고 앞으로도 이런 기회가 생긴다면 참가하고 싶습니다. 이런 자리 만들어주신 하랑에 감사드립니다.",
    nickname: "정락영",
    createdAt: "2019/01/12",
  },
  {
    id: nanoid(10),
    contents:
      "무엇보다 같은 학교 선배님과 대화를 나눈 것이 제일 좋았습니다. 덕분에 대학에 대한 기본적인 지식을 쌓을 수 있었고, 제가 평소 관심있어 하던 학교프로그램(교환학생, 복수전공)등을 알 수 있어 유익했습니다! 감사합니다&#128522;",
    nickname: "khn_0504",
    createdAt: "2018/12/22",
  },
  {
    id: nanoid(10),
    contents:
      "멘토님들과 함께해서 너무 뜻깊고 의미있는 시간이었습니다. &#129505; 4년 대학생활의 길잡이가 되어주셔서 감사합니다. 하랑을 알게 되어서 참 다행입니다!",
    nickname: "yeonghyeon0403",
    createdAt: "2019/01/05",
  },
  {
    id: nanoid(10),
    contents:
      "선배님들과 좋은 시간을 가질 수 있어서 너무 행복했습니다!! 그리고 저의 삶에 도움되는 말씀을 많이 해주시고 애정 가득한 응원해주셔서 감사드립니다. 다음에 기회가 된 다면 참여 할 수 있었으면 좋겠습니다&#128515;",
    nickname: "tpfl1123",
    createdAt: "2019/01/12",
  },

  {
    id: nanoid(10),
    contents:
      "지금까지는 대학에 어떻게 갈까 어떤 장래희망을 가져야 할까에 대해서만 고민했었는데 오늘 하랑 모임에서는 진정으로 나는 어떤 사람일까, 또 어떤 삶을 살고 싶은가에 대해 함께 이야기 할 수 있어서 좋았습니다. 대학 들어가기전 전공신청 꿀팁이랑 대학생활 관련한 궁금증들도 풀 수있어서 너무 좋았습니다. 이런 좋은기회 만들어주셔서 감사하고 하랑 앞으로도 파이팅입니다&#129505;",
    nickname: "baeejh4763",
    createdAt: "2019/12/02",
  },

  {
    id: nanoid(10),
    contents:
      "선배분들께서 너무나 친절하고 상냥하게 대해주셔서 너무 좋았고 대학생활 뿐만 아니라 인생이나 삶 이야기도 해주셔서 너무 좋았고 유익했어요! 인물퀴즈도 하고 다양한 이야기도 해서 오늘 하루 중에 정말 즐거운 시간이었던 것 같습니다!! 그리고 제 세계관과 맞는 분들도 많고 좋았어요!! 선배분들도 짱짱 좋아요!!!ㅎㅎㅎ",
    nickname: "설아",
    createdAt: "2019/12/14",
  },
  {
    id: nanoid(10),
    contents:
      "대학생활 팁 많이 알 수 있었고 선배분들 너무 친절하셔서 좋았어요. 꿈과 비전에 대해 다시 한번 깊이 생각해 볼 수 있도록 이야기해주셔서 감사합니다. 정말 뜻 깊은 시간이었어요! 집에서 나가기 귀찮아서 나가지말까 고민도 했는데 안 갔으면 큰일날 뻔 했어요! 감사합니다!",
    nickname: "iamanne1",
    createdAt: "2019/12/14",
  },
];
