import React, { useState } from "react";
import classNames from "classnames";

import styles from "./Image.module.scss";

interface Props {
  filter?: boolean;
  objectFit?: "cover" | "contain";
}

export default function Image(
  props: React.ImgHTMLAttributes<HTMLImageElement> & Props
) {
  const { width, height, filter = false, objectFit = "cover", ...rest } = props;
  const [loaded, setLoaded] = useState(false);

  return (
    <div
      style={{
        width: width ?? "initial",
        height: height ?? "initial",
      }}
      className={classNames({ [styles.filter_container]: filter })}
    >
      <img
        className={classNames(styles.image, { [styles.loaded]: loaded })}
        style={{ objectFit }}
        loading="eager"
        draggable={false}
        onLoad={() => {
          setLoaded(true);
        }}
        {...rest}
      />
    </div>
  );
}
