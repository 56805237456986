import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { mentoring_2023 } from "assets/posters";
import { usePost } from "client/request";

import Button from "components/Button";
import FieldGroup from "components/FieldGroup";
import Image from "components/Image";

import {
  attendDatePairs,
  type MentoringFormData,
  schema,
  terms,
} from "../fixtures";

import styles from "./Home.module.scss";

export default function Mentoring() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<MentoringFormData>({
    resolver: yupResolver(schema),
  });

  const { post: postMetoring, isLoading } = usePost<MentoringFormData>(
    "mentoringApplication",
    () => {
      toast.success("신청을 완료했습니다. 문자 연락을 기다려주세요.");
      reset();
    }
  );

  const onSubmit = (data: MentoringFormData) => {
    postMetoring(data);
  };

  return (
    <div className={styles.youth_day}>
      <div className={styles.content__left}>
        <Image
          src={mentoring_2023}
          alt="하랑 멘토링"
          width="100%"
          height="auto"
        />
      </div>
      <div className={styles.content__right}>
        <h2>신청 정보</h2>

        <h3>
          * 회비는 각 회차당 <strong>5,000원</strong>입니다. 장소 대관료, 간식비
          등 행사 제반에 사용될 거예요. 참석 당일 제출하거나 계좌이체 해주세요.
          🤗
          <br />
          <span>(카카오뱅크 3333-14-5750325 박상민)</span>
        </h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldGroup>
            <h4>신청일(복수 선택)</h4>
            <div className="checkbox_group">
              {attendDatePairs.map((el) => (
                <div key={el.value}>
                  <input
                    {...register("attendDate")}
                    value={el.value}
                    type="checkbox"
                    id={el.value}
                  />
                  <label htmlFor={el.value}>{el.label}</label>
                </div>
              ))}
            </div>
            <p>{errors.attendDate?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>이름</h4>
            <input {...register("name")} />
            <p>{errors.name?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>닉네임(선택)</h4>
            <input
              {...register("nickname")}
              placeholder="멘토링에서 사용할 닉네임을 알려주세요"
            />
            <p>{errors.nickname?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>대학</h4>
            <input {...register("university")} placeholder="OO 대학교" />
            <p>{errors.university?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>전공</h4>
            <input {...register("major")} />
            <p>{errors.major?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>휴대폰 번호</h4>
            <input
              {...register("mobile")}
              type="tel"
              placeholder="01012345678"
            />
            <p>{errors.mobile?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>기대하는 바</h4>
            <textarea
              {...register("expectation")}
              rows={3}
              placeholder="기대하는 바를 알려주세요"
            />
            <p>{errors.expectation?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <input {...register("term")} id="term" type="checkbox" />
            <label htmlFor="term">개인정보 수집 이용에 동의합니다.</label>
            <div className={styles.term_box}>
              <p>개인정보 수집 이용 동의</p>
              <ul>
                {terms.map((term) => (
                  <li key={term.id}>{term.content}</li>
                ))}
              </ul>
            </div>
            <p>{errors.term?.message}</p>
          </FieldGroup>

          <Button
            type="submit"
            align="center"
            disabled={isLoading}
            loading={isLoading}
          >
            제출하기
          </Button>
          {/* TODO 신청확인 페이지 만들기 */}
          {/* <Link to="/youth-day/find" className={styles.findBtn}>
            신청 확인
          </Link> */}
        </form>
      </div>
    </div>
  );
}
