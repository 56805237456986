import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

import "dayjs/locale/ko";

dayjs.locale("ko");
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

export { dayjs };

export * from "./color";
export * from "./string";
