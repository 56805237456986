import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { youth_day_2023 } from "assets/posters";
import { usePost } from "client/request";

import Button from "components/Button";
import FieldGroup from "components/FieldGroup";
import Image from "components/Image";

import {
  schema,
  schoolYearPairs,
  terms,
  worryPairs,
  type YouthDayFormData,
} from "../fixtures";

import styles from "./Home.module.scss";

export default function YouthDay() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<YouthDayFormData>({
    resolver: yupResolver(schema),
  });

  const { post: postYouthDay, isLoading } = usePost<YouthDayFormData>(
    "youthDayApplication",
    () => {
      toast.success("신청을 완료했습니다. 문자 연락을 기다려주세요.");
      reset();
    }
  );

  const onSubmit = (data: YouthDayFormData) => {
    postYouthDay(data);
  };

  return (
    <div className={styles.youth_day}>
      <div className={styles.content__left}>
        <Image
          src={youth_day_2023}
          alt="하랑 멘토링"
          width="100%"
          height="auto"
        />
      </div>
      <div className={styles.content__right}>
        <h2>신청 정보</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldGroup>
            <h4>고민거리</h4>
            <div className="radio_group">
              {worryPairs.map((el) => (
                <div key={el.value}>
                  <input
                    {...register("worry")}
                    value={el.value}
                    type="radio"
                    id={el.value}
                  />
                  <label htmlFor={el.value}>{el.label}</label>
                </div>
              ))}
            </div>
            <p>{errors.worry?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>이름</h4>
            <input {...register("name")} />
            <p>{errors.name?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>학교</h4>
            <input {...register("schoolName")} placeholder="OO 고등학교" />
            <p>{errors.schoolName?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>학년(올해 기준)</h4>
            <select {...register("schoolYear")}>
              {schoolYearPairs.map((el) => (
                <option key={el.value} value={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
            <p>{errors.schoolYear?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>사는 곳</h4>
            <input {...register("address")} placeholder="서울시 서초구" />
            <p>{errors.address?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>휴대폰 번호</h4>
            <input
              {...register("mobile")}
              type="tel"
              placeholder="01012345678"
            />
            <p>{errors.mobile?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <h4>기대하는 바</h4>
            <textarea
              {...register("expectation")}
              rows={3}
              placeholder="기대하는 바를 알려주세요"
            />
            <p>{errors.expectation?.message}</p>
          </FieldGroup>

          <FieldGroup>
            <input {...register("term")} id="term" type="checkbox" />
            <label htmlFor="term">개인정보 수집 이용에 동의합니다.</label>
            <div className={styles.term_box}>
              <p>개인정보 수집 이용 동의</p>
              <ul>
                {terms.map((term) => (
                  <li key={term.id}>{term.content}</li>
                ))}
              </ul>
            </div>
            <p>{errors.term?.message}</p>
          </FieldGroup>

          <Button
            type="submit"
            align="center"
            disabled={isLoading}
            loading={isLoading}
          >
            제출하기
          </Button>
          {/* TODO 신청확인 페이지 만들기 */}
          {/* <Link to="/youth-day/find" className={styles.findBtn}>
            신청 확인
          </Link> */}
        </form>
      </div>
    </div>
  );
}
