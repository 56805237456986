import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import Banner from "components/Banner";
import Logo from "components/Logo";

import { navLinks } from "./fixtures";
import useDarkMode from "./useDarkMode";

import styles from "./Header.module.scss";

export default function Header() {
  const [isDarkMode] = useDarkMode(40, 100);

  return (
    <header className={styles.header}>
      <Banner />
      <nav className={classNames(styles.nav, { [styles.white]: !isDarkMode })}>
        <div className={styles.nav__left}>
          <NavLink to="/">
            <Logo size="6rem" color={isDarkMode ? "blue" : "white"} />
          </NavLink>
        </div>
        <div className={styles.nav__right}>
          {navLinks.map((navLink) => (
            <NavLink to={navLink.pathname} key={navLink.pathname}>
              {navLink.displayName}
            </NavLink>
          ))}
        </div>
      </nav>
    </header>
  );
}
