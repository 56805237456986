export const replaceCharAt = (
  str: string,
  index: number,
  replacement: string
) => {
  return (
    str.substring(0, index) +
    replacement +
    str.substring(index + replacement.length)
  );
};

export const isEmptyString = (str: string) => {
  return typeof str === "string" && str.trim().length === 0;
};
