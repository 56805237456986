import React from "react";
import { isEmptyString } from "utils";

import styles from "./SectionTitle.module.scss";

interface Props {
  children: React.ReactNode;
  subTitle?: JSX.Element;
}

export default function SectionTitle({ children, subTitle = <></> }: Props) {
  return (
    <div className={styles.section_title}>
      <h3 className={styles.main}>{children}</h3>
      <p className={styles.sub}>{subTitle}</p>
    </div>
  );
}
