import { AiOutlineStar } from "react-icons/ai";
import { Route, Routes } from "react-router-dom";
import { scrollToTop } from "hooks";
import Home from "pages/Home";
import MentoringHome from "pages/Mentoring/Home";
import NoMatch from "pages/NoMatch";
import YouthDayFind from "pages/YouthDay/Find";
import YouthDayHome from "pages/YouthDay/Home";

import Layout from "components/Layout";
import LayoutApply from "components/Layout/Apply";

import "./styles/App.scss";

export default function App() {
  scrollToTop();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route
          path="youth-day"
          element={
            <LayoutApply
              h1={
                <>
                  Youth Day <AiOutlineStar size="0.7em" />
                </>
              }
            />
          }
        >
          <Route index element={<YouthDayHome />} />
          <Route path="find" element={<YouthDayFind />} />
        </Route>
        <Route
          path="mentoring"
          element={<LayoutApply h1={<>프렌즈 온더 블럭</>} />}
        >
          <Route index element={<MentoringHome />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
