import { ReactComponent as Waves } from "./waves.svg";

import styles from "./Hero.module.scss";

export default function Hero() {
  return (
    <section className={styles.hero}>
      <div className={styles.circle}>
        <div className={styles.ship_wrapper}>
          <div className={styles.ship}>
            <span></span>
            <span className={styles.mast}></span>
            <span className={styles.jib}></span>
            <span className={styles.jib_inner}></span>
            <span className={styles.mainsail}></span>
            <span className={styles.mainsail_inner}></span>
          </div>
        </div>

        <Waves />
      </div>

      <h1>
        <span>대학생활은</span>&nbsp;<span>속도보다 방향</span>
      </h1>
    </section>
  );
}
