import React from "react";
import classNames from "classnames";

import styles from "./Marquee.module.scss";
interface Props {
  children: React.ReactNode;
  direction?: "left" | "right";
  gap?: string;
  duration?: number;
}

export default function Marquee({
  children,
  direction = "right",
  gap = "4rem",
  duration = 90,
}: Props) {
  return (
    <div className={styles.marquee} style={{ gap }}>
      <div
        className={classNames(styles.content, [styles[direction]])}
        style={{ gap, animationDuration: `${duration}s` }}
      >
        {children}
      </div>
      <div
        className={classNames(styles.content, [styles[direction]])}
        aria-hidden="true"
        style={{ gap, animationDuration: `${duration}s` }}
      >
        {children}
      </div>
    </div>
  );
}
