import React, { type ButtonHTMLAttributes, type ReactNode } from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "blue";
  loading?: boolean;
  children: ReactNode;
  align?: "center" | "leff" | "right";
}

export default function Button({
  variant = "blue",
  loading = false,
  children,
  align = "leff",
  ...rest
}: Props) {
  return (
    <button
      className={classNames(styles.button, [styles[variant]], [styles[align]], {
        [styles.loading]: loading,
      })}
      {...rest}
    >
      {loading && <div className={styles.spinner}></div>}
      <div className={styles.btnText}>{children}</div>
    </button>
  );
}
