import * as yup from "yup";

const ATTEND_DATE_VALUES = ["step1", "step2", "step3"] as const;
type AttendDateValuesTuple = typeof ATTEND_DATE_VALUES; // readonly ['hearts', 'diamonds', 'spades', 'clubs']
type AttendDateValues = AttendDateValuesTuple[number];

export type MentoringFormData = yup.InferType<typeof schema>;

export const attendDatePairs: Array<{
  label: string;
  value: AttendDateValues;
}> = [
  { label: "STEP1 3월 4일", value: "step1" },
  { label: "STEP2 3월 18일", value: "step2" },
  { label: "STEP3 4월 1일", value: "step3" },
];

export const schema = yup.object({
  name: yup
    .string()
    .required("이름을 입력해주세요")
    .matches(/^[가-힣]{2,4}$/, "2~4자 한글로 입력해주세요"),
  nickname: yup
    .string()
    .test("nickname", "2~10자 한글로 입력해주세요", (value) => {
      if (value === undefined) return true;
      if (value.length === 0) return true;
      return /^[가-힣]{2,10}$/.test(value);
    }),
  university: yup
    .string()
    .required("대학을 입력해주세요")
    .matches(
      /^[가-힣|a-z|A-Z|0-9|\s]+$/,
      "한글, 영어, 숫자, 공백만 입력 가능해요"
    ),
  major: yup
    .string()
    .required("전공을 입력해주세요")
    .matches(
      /^[가-힣|a-z|A-Z|0-9|\s]+$/,
      "한글, 영어, 숫자, 공백만 입력 가능해요"
    ),
  attendDate: yup.array().min(1, "신청일을 하나 이상 선택해주세요"),
  mobile: yup
    .string()
    .required("연락처를 입력해주세요")
    .matches(/^01[0-1, 7][0-9]{7,8}$/, "10~11자리의 숫자만 입력 가능해요"),
  expectation: yup.string(),
  term: yup
    .boolean()
    .required("동의에 체크해 주세요")
    .oneOf([true], "동의에 체크해 주세요"),
});

export const terms = [
  { id: 1, content: "수집항목: 이름, 휴대폰 번호" },
  { id: 2, content: "수집목적: 멘토링 행사 진행 및 안내" },
  { id: 3, content: "보유기간: 멘토링 행사 직후 파기" },
];
