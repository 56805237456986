import React from "react";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import classNames from "classnames";
import { type Size } from "types";

import styles from "./Logo.module.scss";
interface Props {
  size: Size;
  color: "blue" | "white";
}

export default function Logo({ size, color }: Props) {
  return (
    <div
      style={{ width: size }}
      className={classNames(styles.logo, styles[color])}
    >
      <LogoIcon />
    </div>
  );
}
