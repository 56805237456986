import React from "react";
import * as persona from "assets/persona";

import Image from "components/Image";
import SectionTitle from "components/SectionTitle";

import styles from "./Hoooking.module.scss";

export default function Hooking() {
  return (
    <section className={styles.hooking}>
      <SectionTitle>대학 입학 전 고민 Top3</SectionTitle>
      <ul className={styles.cards}>
        {cards.map((card, idx) => (
          <li key={card.id}>
            <Image
              src={Object.values(persona)[idx]}
              width="10rem"
              height="10rem"
              objectFit="contain"
            />
            <div>{card.content}</div>
          </li>
        ))}
      </ul>
    </section>
  );
}

const cards = [
  {
    id: 1,
    content: "진로나 대학생활에 궁금한 게 많은데 물어볼 사람이 없어요.",
  },
  {
    id: 2,
    content:
      "인간관계, 학점, 동아리, 아르바이트... 뭐부터 해야할지 모르겠어요.",
  },
  { id: 3, content: "다른 학교나 전공의 사람들을 만나보고 싶어요." },
];
