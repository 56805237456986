export const getRealBackgroundColor = (elem: Element | null): string => {
  const transparent = "rgba(0, 0, 0, 0)";
  const transparentIE11 = "transparent";

  if (elem == null) return "rgb(255, 255, 255)";

  const bg = getComputedStyle(elem).backgroundColor;

  if (bg === transparent || bg === transparentIE11) {
    return getRealBackgroundColor(elem.parentElement);
  } else {
    return bg;
  }
};

export const determineColorLight = (
  R: number,
  G: number,
  B: number,
  A: number
) => {
  const brightness = R * 0.299 + G * 0.587 + B * 0.114 + (1 - A) * 255;
  return brightness > 186;
};
