import { useFetchItems } from "client/request";

// import { useLifecycles } from "react-use";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Hero from "./components/Hero";
import Hooking from "./components/Hooking";
import Reviews from "./components/Reviews";

import styles from "./Home.module.scss";

export default function Home() {
  const { items } = useFetchItems("missionsSailor", ["reviews"]);

  return (
    <div className={styles.home}>
      <Hero />
      <Hooking />
      <About />
      <Reviews />
      <FAQ />
    </div>
  );
}
