export const seniorsInfo = [
  {
    id: 1,
    name: "강진아",
    desc: "이화여대 신산업융합대학 강사",
    season: 1,
  },
  {
    id: 2,
    name: "이현우",
    desc: "서울대학교 화학과 교수",
    season: 1,
  },
  {
    id: 3,
    name: "가현욱",
    desc: "카이스트 융합기초과학기술학부 교수",
    season: 1,
  },
  {
    id: 4,
    name: "이설아",
    desc: "제일기획 프로듀서",
    season: 2,
  },
  {
    id: 5,
    name: "이승재",
    desc: "S생명 헤리티지센터 팀장",
    season: 4,
  },
  {
    id: 6,
    name: "박노연",
    desc: "정신건강의학과 개원의",
    season: 5,
  },

  {
    id: 7,
    name: "송희종",
    desc: "강남 소재 회계법인 공인회계사",
    season: 9,
  },
  {
    id: 8,
    name: "최솔아",
    desc: "용인 소재 고등 화학교사",
    season: 10,
  },
  {
    id: 9,
    name: "이가림",
    desc: "성균관대 정치외교학과 박사과정",
    season: 11,
  },
];
