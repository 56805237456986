import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { determineColorLight, getRealBackgroundColor } from "utils";

export default function useDarkMode(
  elementPointX: number,
  elementPointY: number
) {
  const [isDarkMode, setDarkMode] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = debounce(() => {
      // 홈페이지, 최상단 히어로 섹션은 밝게 처리
      // if (pathname === "/" && window.pageYOffset < window.innerHeight - 60) {
      //   setDarkMode(determineColorLight(0, 0, 0, 1));
      //   return;
      // }

      const elem = document.elementFromPoint(elementPointX, elementPointY);

      const bgRGB = getRealBackgroundColor(elem)
        .replace(/[^\d,]/g, "")
        .split(",");

      setDarkMode(
        determineColorLight(
          Number(bgRGB[0]),
          Number(bgRGB[1]),
          Number(bgRGB[2]),
          bgRGB.length >= 4 ? Number(bgRGB[3]) : 1
        )
      );
    }, 300);
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  return [isDarkMode];
}
