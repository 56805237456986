import * as seniors from "assets/seniors";

import Image from "components/Image";
import Logo from "components/Logo";
import SectionTitle from "components/SectionTitle";

import { seniorsInfo } from "./fixture";

import styles from "./About.module.scss";

export default function About() {
  return (
    <section className={styles.about}>
      <div className={styles.inner}>
        <SectionTitle>
          <Logo color="white" size="3em" />
        </SectionTitle>
        <div className={styles.desc}>
          하랑은 <strong>크리스쳔 대학생들과 청년들</strong>로 이루어져
          있습니다. <strong>하랑의 비전</strong>이자 목표는 함께 사는 세상에서
          큰 사람이 되어 좋은 영향력을 끼치는 것과 그러한 꿈을 함께 꾸고자 하는
          이들을 서포트하는 것입니다.
          <br />
          고등학교와 달리 다방면으로 펼쳐지는 대학은 <strong>바다</strong>로
          그리고 대학생활은 <strong>항해</strong>로 비유할수 있습니다. 대학 4년
          뒤의 모습은 어떤 가치관을 갖고, 누구를 만나고, 어떤 선택을 하는가에
          따라 달라집니다.
          <br />
          인생의 항해를 시작하는 여러분의 대학생활에 하랑이 함께 하고자 합니다.
        </div>
        <ul className={styles.seniors}>
          {Object.values(seniors).map((el, idx) => (
            <li key={idx}>
              <Image
                src={el}
                width="12rem"
                height="12rem"
                objectFit="contain"
              />
              <div className={styles.senior_name}>
                <span>{seniorsInfo[idx].season}기</span>
                {seniorsInfo[idx].name}
              </div>
              <div className={styles.senior_desc}>{seniorsInfo[idx].desc}</div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
