import {
  collection,
  doc,
  // type CollectionReference,
  type DocumentData,
  getFirestore,
  type PartialWithFieldValue,
  type QueryDocumentSnapshot,
} from "firebase/firestore/lite";
import { type Review, type YouthDayDto } from "types";

import { firebase } from "./firebase";

export const db = getFirestore(firebase);

export const converter = <T extends Record<string, any>>() => ({
  toFirestore({ id, ...data }: PartialWithFieldValue<T>): DocumentData {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<T>): T {
    const data = snapshot.data();
    return {
      id: snapshot.id,
      ...data,
    };
  },
});

export type CollectionName =
  | "missionsSailor"
  | "youthDayApplication"
  | "mentoringApplication";

const createCollection = <T extends Record<string, any>>(
  collectionName: CollectionName
) => {
  return collection(db, collectionName).withConverter(converter<T>());
};

export const reviewCollectionRef = createCollection<Review>("missionsSailor");
export const youtuDayCollectionRef = createCollection<YouthDayDto>(
  "youthDayApplication"
);
