import React from "react";

import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.copy}>
          <p>&copy; {new Date().getFullYear()} 하랑</p>
          <p>Web Produced by cozyzoey</p>
        </div>
      </div>
    </footer>
  );
}
