import React from "react";
import { useNavigate } from "react-router-dom";

import Marquee from "components/Marquee";

import styles from "./Banner.module.scss";

export default function Banner() {
  const navigate = useNavigate();

  return (
    <div
      className={styles.banner}
      onClick={() => {
        navigate("/mentoring");
      }}
    >
      <Marquee gap="1.4em" duration={80}>
        {Array.from(Array(10).keys()).map((el) => (
          <React.Fragment key={el}>
            <span className={styles.element}>2023 토크 멘토링</span>
            <span className={styles.element}>프렌즈 온더 블록</span>
          </React.Fragment>
        ))}
      </Marquee>
    </div>
  );
}
