import React from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";

import styles from "./Layout.module.scss";

export default function Layout() {
  return (
    <>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer />
      <Toaster toastOptions={{ duration: 3600 }} />
    </>
  );
}
