import * as yup from "yup";

export type SchoolYearValues = "1" | "2" | "3";
export type WorryValues = "관계/성격" | "학업" | "진로/진학" | "외모";
export type YouthDayFormData = yup.InferType<typeof schema>;

export const schoolYearPairs: Array<{
  label: string;
  value: SchoolYearValues;
}> = [
  { label: "1학년", value: "1" },
  { label: "2학년", value: "2" },
  { label: "3학년", value: "3" },
];

export const worryPairs: Array<{
  label: string;
  value: WorryValues;
}> = [
  { label: "관계/성격", value: "관계/성격" },
  { label: "학업", value: "학업" },
  { label: "진로/진학", value: "진로/진학" },
  { label: "외모", value: "외모" },
];

export const schema = yup
  .object({
    name: yup
      .string()
      .required("이름을 입력해주세요")
      .matches(/^[가-힣]{2,4}$/, "2~4자 한글로 입력해주세요"),
    schoolName: yup
      .string()
      .required("학교 이름을 입력해주세요")
      .matches(
        /^[가-힣|a-z|A-Z|0-9|\s]+$/,
        "한글, 영어, 숫자, 공백만 입력 가능해요"
      ),
    schoolYear: yup
      .mixed<SchoolYearValues>()
      .required("학년을 선택해주세요")
      .oneOf(schoolYearPairs.map((el) => el.value)),
    address: yup
      .string()
      .required("주소를 입력해주세요")
      .matches(
        /^[가-힣|a-z|A-Z|0-9|\s]+$/,
        "한글, 영어, 숫자, 공백만 입력 가능해요"
      )
      .min(2, "2자 이상 입력해주세요"),
    mobile: yup
      .string()
      .required("연락처를 입력해주세요")
      .matches(/^01[0-1, 7][0-9]{7,8}$/, "10~11자리의 숫자만 입력 가능해요"),
    expectation: yup.string(),
    worry: yup
      .mixed<WorryValues>()
      .required("고민거리를 선택해주세요")
      .oneOf(worryPairs.map((el) => el.value)),
    term: yup
      .boolean()
      .required("동의에 체크해 주세요")
      .oneOf([true], "동의에 체크해 주세요"),
  })
  .required();

export const terms = [
  { id: 1, content: "수집항목: 이름, 휴대폰 번호" },
  { id: 2, content: "수집목적: 멘토링 행사 진행 및 안내" },
  { id: 3, content: "보유기간: 멘토링 행사 직후 파기" },
];
