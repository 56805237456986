import { useMutation, useQuery } from "@tanstack/react-query";
import {
  collection,
  doc,
  type DocumentReference,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
  type WithFieldValue,
} from "firebase/firestore/lite";
import { nanoid } from "nanoid";
import { dayjs } from "utils";

import { type CollectionName, db } from "./firestore";

const post = async <T>(
  collectionName: CollectionName,
  data: WithFieldValue<T>
) => {
  await setDoc<T>(
    doc(
      db,
      collectionName,
      `${dayjs().format("YYMMDD-HH-mm")}-${nanoid(5)}`
    ) as DocumentReference<T>,
    data
  );
};

export const usePost = <T>(
  collectionName: CollectionName,
  onSuccess?: () => void
) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: T) => {
      await post(collectionName, {
        ...data,
        createdAt: Timestamp.now(),
      });
    },
    onSuccess: () => {
      !(onSuccess === undefined) && onSuccess();
    },
    onError(error, variables, context) {
      console.log(error);
    },
  });

  return { post: mutate, isLoading };
};

export const useFetchItem = (
  collectionName: CollectionName,
  queryKey: string[]
) => {
  const { data: item = {} } = useQuery({
    queryKey,
    queryFn: async () => await getDoc(doc(db, collectionName)),
  });

  return { item };
};

const fetchItems = async (collectionName: CollectionName) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const items = querySnapshot.docs.map((doc) => doc.data());
  return items;
};

export const useFetchItems = (
  collectionName: CollectionName,
  queryKey: string[]
) => {
  const { data: items = [] } = useQuery({
    queryKey,
    queryFn: async () => await fetchItems(collectionName),
  });

  return { items };
};
